import React, { useState, useEffect } from 'react'

import FunFact from './FunFact'
import { getFacts, voteOnFact } from '../../actions/factActions'
import FunFactSkeletonList from '../shared/Loaders/FunFactSkeletonList'

const FunFactCardList = () => {
    const [facts, setFacts] = useState([])
    const [loadingFacts, setLoadingFacts] = useState(true)
    const [loadingError, setLoadingError] = useState(null)
    const [hasVotedFacts, setHasVotedFacts] = useState({})

    useEffect(() => {
        let isMounted = true
        getFacts()
            .then(data => {
                if (isMounted) setFacts(data)
            })
            .catch(error => {
                // Upgrade error handling - the factActions is implementing the catch, this is useless
                if (isMounted) {
                    setLoadingFacts(false)
                    setLoadingError(error)
                }
            })
            .finally(() => {
                if (isMounted) setLoadingFacts(false)
            })
    }, [])

    const updateVotedFacts = factId => {
        let hasVotedFactsTemp = JSON.parse(JSON.stringify(hasVotedFacts))
        hasVotedFactsTemp[factId] = true
        setHasVotedFacts(hasVotedFactsTemp)
    }

    const updateFacts = (funRating, factId) => {
        let factList = JSON.parse(JSON.stringify(facts))
        const factToModifyIndex = factList.findIndex(f => f.id === factId)

        if (factToModifyIndex >= 0) {
            factList[factToModifyIndex].funRating = funRating
            factList[factToModifyIndex].numTotalRatings += 1
        }

        setFacts(factList)
        updateVotedFacts(factId)
    }

    const sortFacts = (a, b) => {
        if (a.funRating === b.funRating) {
            return b.numTotalRatings - a.numTotalRatings
        }

        return a.funRating < b.funRating ? 1 : -1
    }

    const voteAsFun = factId => {
        return voteOnFact(factId, 'FUN')
            .then(data => {
                updateFacts(data.values.funRating, factId)
                return
            })
            .catch(error => {
                // do something with error
                return
            })
    }

    const voteAsNotFun = factId => {
        return voteOnFact(factId, 'NOT_FUN')
            .then(data => {
                updateFacts(data.values.funRating, factId)
                return
            })
            .catch(error => {
                // do something with error
                return
            })
    }

    return (
        <>
            {loadingFacts ? (
                <ul className="grid grid-cols-1 gap-6">
                    <FunFactSkeletonList />
                </ul>
            ) : (
                loadingError ?
                    <div>
                        Error loading facts. Please try refreshing the page.
                    </div>
                    :
                    <ul className="grid grid-cols-1 gap-6">
                        {facts &&
                            facts.length > 0 &&
                            facts
                                .sort(sortFacts)
                                .map(fact => (
                                    <FunFact
                                        fact={fact}
                                        key={
                                            JSON.stringify(fact) +
                                            hasVotedFacts[fact.id]
                                        }
                                        voteAsFunFn={voteAsFun}
                                        voteAsNotFunFn={voteAsNotFun}
                                        hasVoted={hasVotedFacts[fact.id]}
                                    />
                                ))}
                    </ul>
            )}
        </>
    )
}

export default FunFactCardList
