import React from 'react'
import Layout from '../components/layout'
import Amplify from 'aws-amplify'

import config from '../aws-exports'
import FunFactCardList from '../components/fun-facts/FunFactCardList'

Amplify.configure(config)

// Amplify.Logger.LOG_LEVEL = 'DEBUG'

const IndexPage = () => {
    return (
        <Layout>
            <FunFactCardList />
        </Layout>
    )
}

export default IndexPage
